<template>
  <NavBar/>
  <div class="main-bg">
    <h2 class="title">
      {{ $t('ПроцедураАренды') }}
    </h2>
    <h3>
      {{ $t('данные') }}
    </h3>
    <ul class="rent-needs">
      <li class="rent-needs-item" v-for="item in items" :key="item.item">
        <img alt="" :src="require(`../assets/imgs/${ item.item.image1 }.png`)">
        <!--        :src="`../assets/imgs/${ item.item.image1 }.png`"-->
        {{ $t(item.item.text) }}
      </li>
    </ul>
    <p>
      {{ $t('info') }}
    </p>
    <p>
      {{ $t('info1') }} <b>{{ $t('info2') }}</b> {{ $t('info3') }}
    </p>
    <p>
      {{ $t('info4') }}
    </p>
  </div>
  <ContactForm/>
</template>

<script>
import NavBar from '../components/NavBar'
import ContactForm from '../components/ContactForm'

export default {
  name: 'Rent',
  components: {
    NavBar,
    ContactForm
  },
  data () {
    return {
      // text: '',
      // image1: '',
      items: [
        {
          item: {
            text: 'аккаунт',
            image1: 'mail'
          }
        },
        {
          item: {
            text: 'почта',
            image1: 'email'
          }
        },
        {
          item: {
            text: 'номертелефона',
            image1: 'smartphone'
          }
        },
        {
          item: {
            text: 'карты',
            image1: 'credit'
          }
        }
      ]
    }
  }
}
</script>

<style scoped>
@import "../assets/base.css";

h3 {
  margin-bottom: 20px;
}

</style>
